import api from "../services/api";

export const CreateFeedback = async (data) =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.post(`/feedback/`, data, {
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
      });
      console.log(JSON.parse(token))
      return response
  } catch (error) {
      console.error('Erro ao criar feedback:', error.request._response);
      console.log(error)
  }
}
export const GetFeedbackEntries = async () =>{
    try {

    const token = localStorage.getItem("@Auth:token");
    const response = await api.get(`/feedback`, {
          headers: {
                Authorization: `Bearer ${JSON.parse(token)}`,
          },
      });
      console.log(JSON.parse(token))
      return response
  } catch (error) {
      console.error('Erro ao exibir feedbacks:', error.request._response);
      console.log(error)
  }
}