import React, {useState, useEffect, useContext} from "react";
import { Sidebar } from "../../components/Sidebar";
import { AuthContext } from "../../context/Auth";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt, FaRegEye } from "react-icons/fa";
import { MdOutlineOpenInNew } from "react-icons/md";
import { createAdminUser, deleteAdminUser, getAllAdminUsers, updateSubscription } from "../../business/AdminBusiness";
import { CiCircleRemove } from "react-icons/ci";
import { DeleteUser, GetAllUsers, imageUserDelete } from "../../business/UserBusiness";
import toast from "react-hot-toast";
import { GetFeedbackEntries } from "../../business/FeedbackBusiness";

export const Complaints = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [complaints, setComplaints] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const [selectedComplaint, setSelectedComplaint] = useState(null)

    const navigate = useNavigate()
  
    const { azureToken } = useContext(AuthContext);
    const token = azureToken?.split('"')?.join('')
  
    const adminName = localStorage.getItem("@Auth:name")
    const idLocal = localStorage.getItem("@Auth:Id")

    const id = idLocal?.split('"')?.join('')
  
    const fetchComplaints = async () => {
      try {
        setIsLoading(true);
        const response = await GetFeedbackEntries();
        console.log(response);
        setComplaints(response.data);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
  
    const formatCreatedAt = (createdAt) => {
      const createdAtDate = new Date(createdAt);
      return createdAtDate.toLocaleDateString("pt-BR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const filteredComplaints = complaints?.filter((complaint) => 
        complaint?.classification?.toLowerCase().includes(searchTerm?.toLowerCase())
    )

    const handleSearchTerm = (e) => {
        setSearchTerm(e.target.value);
      };
  
  
    useEffect(() => {
        fetchComplaints();
    }, []);

    return(
        <>
        <div className="flex flex-row">
            <Sidebar/>
            <div className="flex flex-col w-10/12 mx-auto py-10">
            <div className="flex flex-col w-10/12 mx-auto ">
              <h1 className="text-xl font-light leading-6">Olá, <span className="font-bold">{adminName}</span></h1>
              <p className="text-lg font-light leading-6 text-gray-700">Denúncias</p>
            </div>
            <div className="flex flex-col w-10/12 mx-auto py-10">
                <div className="flex flex-row justify-between">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                    >
                        Pesquise pela classificação do feedback ({complaints.length} feedbacks)
                    </label>

                </div>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Ache pelo tipo da denúncia..."
                  onChange={handleSearchTerm}
                  value={searchTerm}
                />
              </div>
            </div>

            <table className="divide-y divide-gray-100 w-10/12 mx-auto">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Feedback</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Quem enviou?</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Classificação do feedback</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Criada em</th>
                        <th className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                {isLoading ? (
                        <div>
                            <p className="py-4 px-6">Carregando...</p>
                        </div>
                    ) : filteredComplaints && filteredComplaints.length > 0 ? (
                        filteredComplaints.map((person) => (
                            <tr
                                key={person?._id}
                                className="hover:bg-gray-100 cursor-pointer"
                                onClick={() => setSelectedComplaint(person)}
                            >
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="text-sm font-medium text-gray-900">{person?.reply}</div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">{person?.username}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person?.classification}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{formatCreatedAt(person?.createdAt)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <div className="text-gray-900 hover:text-indigo-600 cursor-pointer">
                                        <FaRegEye size={20} color="grey-500"/>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <div className="py-4 px-6">Nenhuma denúncia registrada</div>
                    )}
                </tbody>
            </table>

            {console.log(selectedComplaint)}

                {selectedComplaint && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center">
                <div className="bg-white p-5 rounded-lg w-100">
                        <CiCircleRemove 
                            onClick={() => setSelectedComplaint(null)}
                            size={28}
                            className="top-2 text-red-600 hover:text-red-800 mb-4 cursor-pointer"
                        >
                            Fechar
                        </CiCircleRemove>
                    <h2 className="text-xl mt-10 font-semibold mb-2">
                    Informações do feedback
                    </h2>
                    <h2 className="text-md font-semibold">
                        Quem enviou o feedback? 
                    </h2>
                    <p className="mb-2">{selectedComplaint?.username}</p>

                    <h2 className="text-md font-semibold">
                        Feedback criado desde: 
                    </h2>
                    <p className="mb-2">{formatCreatedAt(selectedComplaint?.createdAt)}</p>

                    <h2 className="text-md font-semibold">
                        Classificação de feedback: 
                    </h2>
                    <p className="mb-2">{selectedComplaint?.classification}</p>

                    <h2 className="text-md font-semibold">
                        Qual feedback? 
                    </h2>
                    <p className="mb-2">{selectedComplaint?.description}</p>
                </div>
                </div>
                )}
        </div>
        </div>


        </>
    )
}