import React, { useState, useEffect, useContext } from "react";
import { DeleteUser, GetAllUsers, imageUserDelete } from "../../business/UserBusiness";
import { AuthContext } from "../../context/Auth";
import { Link } from "react-router-dom";
import { FaRegTrashAlt, FaRegEye } from "react-icons/fa";
import { MdOutlineOpenInNew } from "react-icons/md";
import { Sidebar } from "../../components/Sidebar";
import { updateSubscription } from "../../business/AdminBusiness";
import toast from "react-hot-toast";

export const Home = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [selectedDelete, setSelectedDelete] = useState(null)
  const [selectedDeleteUser, setSelectedDeleteUser] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState(null)

  const { azureToken } = useContext(AuthContext);
  const token = azureToken?.split('"')?.join('')

  const adminName = localStorage.getItem("@Auth:name")


  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await GetAllUsers();
      console.log(response);
      setUsers(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const confirmDeleteImage = async (imageId) => {
    try {
        await imageUserDelete(selectedUserId, imageId)
        toast.success('Imagem excluída com sucesso!')
        setSelectedDelete(null)
    } catch (error) {
        console.error('Erro ao excluir imagem do usuário:', error)
    }
}

const confirmDeleteUser = async (userId) => {
  try {
      await DeleteUser(userId);      
      toast.success('Usuário foi removido com sucesso!');
      setSelectedDeleteUser(null)

  } catch (error) {
      console.error('Erro ao excluir conta do usuário:', error);
      toast.error('Erro ao deletar conta de usuário')
  }

};

  const filteredUsers = users?.filter((user) => 
    user?.username?.toLowerCase().includes(searchTerm?.toLowerCase())
  )

  const handleSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleUpdateSubscription = async (subscriptionValue) => {
    try{
      await updateSubscription(selectedUserId, {subscription: subscriptionValue})
      toast.success('Pronto! Atualize a página para ver as alterações')
    } catch (err) {
      toast.error('Erro ' + err)
      console.log(err)
    }
  }

  const handlePersonClick = (person) => {
    setSelectedPerson(person);
    setSelectedUserId(person._id)
  };

  console.log(selectedUserId)

  const handleModalClose = () => {
    setSelectedPerson(null);
  };

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("pt-BR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };


  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="flex flex-row">

        <Sidebar/>

        <div className="flex flex-col w-10/12 mx-auto py-10">
            <div className="flex flex-col w-10/12 mx-auto ">
              <h1 className="text-xl font-light leading-6">Olá, <span className="font-bold">{adminName}</span></h1>
              <p className="text-lg font-light leading-6 text-gray-700">Dashboard</p>
            </div>
            <div className="flex flex-col w-10/12 mx-auto py-10">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Pesquise pelo nome ({filteredUsers.length} usuários)
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Digite um nome..."
                  onChange={handleSearchTerm}
                  value={searchTerm}
                />
              </div>
            </div>

            <table className="divide-y divide-gray-100 w-10/12 mx-auto items-center">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Premium</th>
                        <th className="px-6 py-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <div>
                            <p className="py-4 px-6">Carregando...</p>
                        </div>
                    ) : (

                      filteredUsers && filteredUsers.length > 0 ? (
                        filteredUsers?.map((person) => (
                          <tr
                              key={person?.email}
                              className="hover:bg-gray-100 cursor-pointer items-center"
                              onClick={() => handlePersonClick(person)}
                          >
                              <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="flex items-center">
                                      <div className="flex-shrink-0 h-12 w-12">
                                        <div className="h-9 w-9 flex items-center justify-center rounded-full bg-gray-300 text-gray-700 font-bold mr-2">
                                          {person?.username
                                            .split(" ")
                                            .map((word) => word[0])
                                            .join("")
                                            .toUpperCase()
                                          }
                                        </div>
                                      </div>
                                      <div className="ml-4">
                                          <div className="text-sm font-medium text-gray-900">{person?.username}</div>
                                      </div>
                                  </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm text-gray-900">{person?.email}</div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person?.subscription === 'active' ? 'Sim' : 'Não'}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                  <div className="text-gray-900 hover:text-indigo-600 cursor-pointer">
                                      <FaRegEye size={20} color="grey-500"/>
                                  </div>
                              </td>
                          </tr>
                        ))
                      ) : (
                        <div className="py-4 px-6">Nenhum usuário registrado</div>
                      )
                    )}
                </tbody>
            </table>

            {selectedPerson && (
              <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center">
                <div className="bg-white p-5 rounded-lg h-4/5 w-100 overflow-y-scroll">
                  <button
                    onClick={handleModalClose}
                    className="top-2 text-gray-600 hover:text-gray-800"
                  >
                    Fechar
                  </button>
                  <h2 className="text-xl mt-6 font-semibold mb-2">
                    Informações de {selectedPerson.username}
                  </h2>
                    <div className="h-9 w-9 flex items-center justify-center rounded-full bg-gray-300 text-gray-700 font-bold mr-2">
                      {selectedPerson.username
                        .split(" ")
                        .map((word) => word[0])
                        .join("")
                        .toUpperCase()
                      }
                    </div>
                  <div className="mx-auto flex flex-row justify-center mb-5">
                    <FaRegTrashAlt size={23} color="red" className="hover:cursor-pointer mr-1" onClick={() => {setSelectedDeleteUser(selectedPerson); handleModalClose()}}/>
                    <Link
                      className="hover:cursor-pointer ml-1"
                      to={`https://dashboard.stripe.com/test/customers/${selectedPerson?.customerId}`}
                      target="_blank"
                    >
                      <MdOutlineOpenInNew size={23} color="purple"/>
                    </Link>
                  </div>

                  <h2 className="text-md font-semibold">
                    Nome:
                  </h2>
                  <p className="mb-2">{selectedPerson?.username}</p>
                  
                  <h2 className="text-md font-semibold">
                    Email:
                  </h2>
                  <p className="mb-2">{selectedPerson?.email}</p>

                  <h2 className="text-md font-semibold">
                    Usuário desde: 
                  </h2>
                  <p className="mb-2">{formatCreatedAt(selectedPerson?.createdAt)}</p>

                  <h2 className="text-md font-semibold">
                    Usuário premium? 
                  </h2>


                  <p className="mb-2">{selectedPerson?.subscription === 'inactive' ? 'Não' : 'Sim'}</p>

                  {selectedPerson?.subscription === 'inactive' ? (
                    <div>
                            <button className="bg-green-800 hover:bg-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none mx-auto focus:shadow-outline" type="button" onClick={() => {handleUpdateSubscription('active'); handleModalClose()}}>
                              Torná-lo usuário premium
                            </button>
                    </div>
                    
                  ) : (
                    <div>
                            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none mx-auto focus:shadow-outline" type="button" onClick={() => {handleUpdateSubscription('inactive'); handleModalClose()}}>
                              Tirar-lhe o premium
                            </button>
                    </div>
                  )}
                </div>
              </div>
            )}


            {selectedDeleteUser && (
              <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-75 flex justify-center items-center">
              <div className="bg-white p-5 rounded-lg w-100">
                <h2 className="text-xl mt-10 font-semibold mb-2">
                  Deseja apagar este usuário?
                </h2>
                <h2 className="text-md mt-10 font-semibold">
                  ID do usuário:
                </h2>
                <p className="mb-2">{selectedDeleteUser?._id}</p>
                <div className="flex items-center justify-between w-80 mx-auto mt-10 mb-8">
                          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => confirmDeleteUser(selectedDeleteUser?._id)}>
                          Sim, quero apagar
                          </button>
                          <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" onClick={() => setSelectedDeleteUser(null)}>
                          Não, desisto
                          </button>
                      </div>
              </div>
            </div>
            )}
        </div>
    </div>
  );
};
